import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { HttpClientService } from 'src/app/core/services/http-client.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpClient: HttpClientService,
    private sessionStorage: SessionStorageService
  ) {}

  login(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/Login`;
    return this.httpClient.post(URL, data, true);
  }

  loginWithGoogle(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/google-login`;
    return this.httpClient.post(URL, data, true);
  }

  loginWithFacebook(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/facebook-login`;
    return this.httpClient.post(URL, data, true);
  }

  loginWithLine(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/line-login`;
    return this.httpClient.post(URL, data, true);
  }

  logout(data: any) {
    const URL: string = environment.apiAuthUrl + `Auth/logout`;
    return this.httpClient.post(URL, data, true);
  }

  updatePassword(params: any) {
    const URL: string = environment.apiAuthUrl + `Auth/UpdatePassword`;
    return this.httpClient.post(URL, params, true);
  }

  checkUser(params: any) {
    const URL: string = environment.apiAuthUrl + `Auth/CheckUser`;
    return this.httpClient.post(URL, params, true);
  }

  checkFarmer(params: any) {
    const URL: string = environment.apiAuthUrl + `Auth/CheckFarmer`;
    return this.httpClient.post(URL, params, true);
  }

  updatePasswordUser(params: any) {
    const URL: string = environment.apiAuthUrl + `Auth/UpdatePasswordUser`;
    return this.httpClient.put(URL, params, true);
  }

  refreshToken() {
    const URL: string = environment.apiAuthUrl + `Auth/refresh`;
    let data = {
      hash: this.sessionStorage.getHash(),
      refreshToken: this.sessionStorage.getRefreshToken(),
      accessToken: this.sessionStorage.getAccessToken(),
    };
    return this.httpClient.post(URL, data, true).pipe(
      tap((resp: any) => {
        this.sessionStorage.setAccessToken(resp.data.accessToken);
        this.sessionStorage.setRefreshToken(resp.data.refreshToken);
        this.sessionStorage.setExpireToken(resp.data.accessTokenExpire);
      })
    );
  }

  register(params: any) {
    const URL: string = environment.apiAuthUrl + `Auth/register`;
    return this.httpClient.post(URL, params, true);
  }
}

import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AppLayoutComponent } from './layout/app.layout.component';
import { CallbackComponent } from './modules/callback/callback.component';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'callback', component: CallbackComponent },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'thaidcallback',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/thaid/thaid.module').then((m) => m.ThaidModule),
  },
  {
    path: 'register',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./modules/setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'sick-animal',
        loadChildren: () =>
          import('./modules/sick-animal/sick-animal.module').then((m) => m.SickAnimalModule),
      },
      {
        path: 'appointment',
        loadChildren: () =>
          import('./modules/appointment/appointment.module').then((m) => m.AppointmentModule),
      },
      {
        path: 'history-appointment',
        loadChildren: () =>
          import('./modules/history-appointment/history-appointment.module').then((m) => m.HistoryAppointmentModule),
      },
      {
        path: 'knowledge-base',
        loadChildren: () =>
          import('./modules/knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule),
      },
      {
        path: 'find-service-point',
        loadChildren: () =>
          import('./modules/find-service-point/find-service-point.module').then((m) => m.FindServicePointModule),
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./modules/notification/notification.module').then((m) => m.NotificationModule),
      }
    ],
  },

  {
    path: 'pagenotfound',
    loadChildren: () =>
      import('./modules/pagenotfound/pagenotfound.module').then(
        (m) => m.PagenotfoundModule
      ),
  },
  {
    path: 'video',
    loadChildren: () =>
      import('./modules/video-call/video-call.module').then((m) => m.VideoCallModule),
  },
  { path: '**', redirectTo: '/pagenotfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private sessionStorageService: SessionStorageService) { }
  isGuest : boolean = false;

  getAccessToken(): boolean {
    const TOKEN = this.sessionStorageService.getAccessToken();
    if (TOKEN) {
      return true;
    } else {
      return false;
    }
  }


  enableGuestMode(){
    this.sessionStorageService.setDataToSessionStorage('guest', true);
  }

  disableGuestMode(){
    this.sessionStorageService.removeDataToSessionStorage('guest');
  }
}

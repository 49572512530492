import { Component, OnInit } from '@angular/core';
import { OauthService } from 'src/app/services/api/oauth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/app/core/services/security.service';
import { AuthService } from 'src/app/services/api/auth.service';
import { Observable, of } from 'rxjs';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { HttpErrorHandlerService } from 'src/app/core/services/http-error-handler.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  constructor(
    private oauthService: OauthService,
    private route: ActivatedRoute,
    private security: SecurityService,
    private router: Router,
    private authService: AuthService,
    private sessionStorage: SessionStorageService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.utils.spinnerShow();
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      const state = this.security.decryptAES128(params['state']);
      const error = params['error'];
      const HASH = this.security.gererateHash();
      let param = {
        authorizationCode: code,
        hash: HASH,
        applicationId: 24,
      }
      if(state != null && error === undefined){
        this.callLoginApi(param, state).subscribe({
          next: (resp: any) => {
              if (resp.status === 200) {
                if(resp.data.isRegistered == true){
                  this.sessionStorage.setAccessToken(resp.data.token.accessToken);
                  this.sessionStorage.setRefreshToken(resp.data.token.refreshToken);
                  this.sessionStorage.setExpireToken(resp.data.token.accessTokenExpire);
                  this.sessionStorage.setUserProfile(resp.data.userProfile);
                  this.sessionStorage.setHash(HASH);
                  this.utils.spinnerHide();
                  this.router.navigate(['/profile']);
                }
                else {
                  let socialData = {
                    ...resp.data,
                    sender: state
                  }
                  this.sessionStorage.setDataToSessionStorage("socialData",socialData);
                  this.router.navigate(['/register']);
                }

              }

          },
          error: (error) => {
            this.utils.spinnerHide();
            this.httpErrorHandlerService.handleError(error)?.then((resp: any) => {
              this.router.navigate(['/login']);
            })
          }
        });
      }else{
        this.utils.spinnerHide();
        this.router.navigate(['/login']);
      }

      }
    );

  }

  callLoginApi(param: any , state :string): Observable<any> {
    if(state === 'googlestate'){
      return this.authService.loginWithGoogle(param);
    }
    else if(state === 'facebookstate' && (param.error !== 'access_denied' || param.error === undefined ) ){
      return this.authService.loginWithFacebook(param);
    }
    else if(state === 'linestate'){
      return this.authService.loginWithLine(param);
    }
    else {
      return of(null);
    }
  }

  }



import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private sessionStorage: SessionStorageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isAnonymous = request.headers.get('Anonymous') === 'true';
    if (!isAnonymous) {
      const token = this.sessionStorage.getAccessToken();
      if (!token) {
        return next.handle(request);
      } else {
        const authReq = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
        return next.handle(authReq);
      }
    } else {
      return next.handle(request);
    }
  }
}

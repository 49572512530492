<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="sidebar-header">
    <a class="logo">
      <div class="logo-image">
        <img src="assets/images/icon-dld.png" class="w-full h-full">
      </div>
      <span class="app-name"><span class="font-bold">DLD</span><span class="font-normal"> Televet</span></span>
    </a>
    <!-- <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button> -->
    <i class="layout-sidebar-anchor cursor-pointer text-white fa-solid fa-bars hover:text-300" (click)="anchor()"></i>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>

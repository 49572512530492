import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SecurityService } from 'src/app/core/services/security.service';

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private security: SecurityService
  ) {

  }

  // initConfiguration() {
  //   const authConfig = {
  //     issuer: environment.googlelink,
  //     strictDiscoveryDocumentValidation: false,
  //     clientId: environment.googleId,
  //     redirectUri: window.location.origin + '/callback',
  //     scope: 'openid profile email',
  //     responseType: 'code',
  //     usePkce: true,
  //     state: 'googlestate',
  //     showDebugInformation: true,
  // }
  // this.oauthService.configure(authConfig);
  // this.oauthService.setupAutomaticSilentRefresh();
  // this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }

  // async initLoginFlow() {
  //   this.initConfiguration();
  //   const codeVerifier = this.generateCodeVerifier();
  //   this.sessionStorageService.setDataToSessionStorage('code_verifier', codeVerifier);
  //   const generateCodeChallenge = await this.generateCodeChallenge(codeVerifier);
  //   this.oauthService.initLoginFlow(generateCodeChallenge);
  // }

  googleLogin() {
    const clientId = environment.googleId;
    const state = this.security.encryptAES128('googlestate');
    const responseType = 'code';
    const redirectUri = encodeURIComponent(window.location.origin + '/callback');
    const customUrl = `${environment.googlelink}client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&scope=openid profile email`;

    window.location.href = customUrl;

  }

  lineLogin(){
    const clientId = environment.lineId;
    const state = this.security.encryptAES128('linestate');
    const scope = encodeURIComponent('profile openid email');
    const redirectUri = encodeURIComponent(window.location.origin + '/callback');
    const customUrl = `${environment.linelink}response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
    window.location.href = customUrl;
  }

  facebookLogin(){
    const clientId = environment.facebookId;
    const state = this.security.encryptAES128('facebookstate');
    const responseType = 'code';
    const redirectUri = encodeURIComponent(window.location.origin + '/callback');
    const customUrl = `${environment.facebooklink}client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&scope=email`;
    window.location.href = customUrl;

  }

  private generateCodeVerifier(): string {
    const array = new Uint32Array(43);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashString = hashArray.map(byte => String.fromCharCode(byte)).join('');
    // Corrected base64 URL encoding
    return btoa(hashString)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }
}

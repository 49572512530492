import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  private publicRoutes = ['/knowledge-base', '/find-service-point'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const fullPath = this.getFullPath(childRoute);

    if (this.isPublicRoute(fullPath)) {
      const isGuest = this.sessionStorageService.getDataToSessionStorage('guest');
      const isAuthenticated = this.authService.getAccessToken();

      // อนุญาตให้เข้าถึงถ้าเป็น guest หรือ authenticated
      return isGuest === true || !!isAuthenticated;
    }

    const hasToken = this.authService.getAccessToken();
    if (!hasToken) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  private getFullPath(route: ActivatedRouteSnapshot): string {
    const paths: string[] = [];
    let current: ActivatedRouteSnapshot | null = route;

    while (current) {
      if (current.routeConfig?.path) {
        paths.unshift(current.routeConfig.path);
      }
      current = current.parent;
    }

    return '/' + paths.join('/');
  }

  private isPublicRoute(path: string): boolean {
    return this.publicRoutes.some(publicPath =>
      path.startsWith(publicPath)
    );
  }
}

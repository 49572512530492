export const environment = {
  apiMstUrl: 'https://dldtelevetapi.kbtdevops.com/mst/api/',
  apiAuthUrl: 'https://dldtelevetapi.kbtdevops.com/auth/api/',
  apiUsrUrl: 'https://dldtelevetapi.kbtdevops.com/usr/api/',
  apiNsaUrl: 'https://dldtelevetapi.kbtdevops.com/noti-sick-animal/api/',
  apiObjUrl: 'https://dldtelevetapi.kbtdevops.com/object-storage/api/',
  apiPdfUrl: 'https://dldtelevetapi.kbtdevops.com/rpt/api/',
  appVersion: '1.0.0',
  appBuildNo: '2024111603',
  rows: 50,
  rowsPerPageOptions: [20, 50, 100],
  thaiDApi: '',
  thaiDToken: '',
  thaiDClientId: '',
  thaiDClientSecret: '',
  thaiDRedirectUri: '',
  thaiDScope: 'pid name birthdate given_name family_name address gender date_of_issuance date_of_expiry',
  googlelink: 'https://accounts.google.com/o/oauth2/v2/auth?',
  googleId: '343141736598-bl1v7rqoh5cvsvtrpf1t59v847pv4fhq.apps.googleusercontent.com',
  linelink:'https://access.line.me/oauth2/v2.1/authorize?',
  lineId:'2006560315',
  facebooklink:'https://www.facebook.com/v11.0/dialog/oauth?',
  facebookId:'1081917073069587',
};

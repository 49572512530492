import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ViewportScroller } from '@angular/common';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { SessionStorageService } from './session-storage.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  validatorMsg: string = 'กรุณากรอกข้อมูล';

  constructor(
    private spinner: NgxSpinnerService,
    private viewPortScroller: ViewportScroller,
    private sessionStorageService: SessionStorageService
  ) {}

  spinnerShow() {
    this.spinner.show(undefined, {
      size: 'medium',
      bdColor: 'rgba(3,3,3, .9)',
      color: '#ffffff',
      fullScreen: true,
    });
  }

  spinnerHide() {
    this.spinner.hide();
  }

  parseDate(dateString: string): Date {
    const day = +dateString.substring(0, 2); // ดึงตัวเลข 2 ตัวแรกเป็นวัน
    const month = +dateString.substring(2, 4) - 1; // ดึงตัวเลข 2 ตัวถัดมาเป็นเดือน (ลบ 1 เพราะเดือนใน JavaScript เริ่มจาก 0)
    const year = +dateString.substring(4, 8); // ดึงตัวเลข 4 ตัวสุดท้ายเป็นปี

    return new Date(year - 543, month, day);
  }

  alertBox$(icon: any, title: any, msg: any) {
    //icon 'success' , 'error' , 'warning' , 'info' , 'question'
    return new Observable<boolean>((observer) => {
      Swal.fire({
        icon: icon,
        title: title,
        text: msg,
        confirmButtonText: 'ตกลง',
        confirmButtonColor: 'var(--cyan-600)',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      })
        .then((result) => {
          observer.next(true);
          observer.complete();
        })
        .catch((ex) => {
          observer.error(ex);
        });
    });
  }

  confirmBox$(icon: any, title: any, msg: any, type?: any) {
    //icon 'success' , 'error' , 'warning' , 'info' , 'question'
    return new Observable<boolean>((observer) => {
      Swal.fire({
        icon: icon,
        title: title,
        text: msg,
        showCancelButton: true,
        confirmButtonColor: 'var(--cyan-600)',
        confirmButtonText: type == 'backPage' ? 'กลับหน้าหลัก' : 'ตกลง',
        cancelButtonColor: '',
        cancelButtonText: type == 'backPage' ? 'ปิด' : 'ยกเลิก',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      })
        .then((result) => {
          if (result.isConfirmed == true) {
            observer.next(true);
            observer.complete();
          } else if (result.isDismissed == true) {
            observer.next(false);
            observer.complete();
          }
        })
        .catch((ex) => {
          observer.error(ex);
        });
    });
  }

  alertBox(icon: any, title: any, msg: any) {
    //icon 'success' , 'error' , 'warning' , 'info' , 'question'
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: icon,
        title: title,
        text: msg,
        confirmButtonText: 'ตกลง',
        confirmButtonColor: 'var(--cyan-600)',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      })
        .then((result) => {
          resolve(result);
        })
        .catch((ex) => {
          resolve(ex);
        });
    });
  }

  confirmBox(icon: any, title: any, msg: any) {
    //icon 'success' , 'error' , 'warning' , 'info' , 'question'
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: icon,
        title: title,
        text: msg,
        showCancelButton: true,
        confirmButtonColor: 'var(--cyan-600)',
        confirmButtonText: 'ตกลง',
        cancelButtonColor: '',
        cancelButtonText: 'ยกเลิก',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      })
        .then((result) => {
          if (result.isConfirmed == true) {
            resolve(true);
          } else if (result.isDismissed == true) {
            resolve(false);
          }
        })
        .catch((ex) => {
          resolve(ex);
        });
    });
  }

  getFloatValue(value: any) {
    if (typeof value === 'string') {
      if (value) {
        value = value.replace(/,/g, '');
        if (value === '') return 0;
        else return parseFloat(value);
      } else {
        return 0;
      }
    } else {
      return value;
    }
  }

  convertBase64ToBlob(base64: string) {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/octet-stream' });
    return blob;
  }

  convertBase64PDFToBlob(base64: string) {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf;base64' });
    return blob;
  }

  converBase64ToFile(bytes: any) {
    let imageName = 'name.png';
    let imageBlob = this.dataURItoBlob(bytes);
    let imageFile = new File([imageBlob], imageName, { type: 'image/png' });
    return imageFile;
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  saveFile(blob: Blob, fileName: string) {
    var link = document.createElement('a');
    var blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = fileName;
    link.click();
  }

  base64ToArrayBuffer(base64: any) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async downloadImageByUrl(url: string, filename: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = filename;
    anchor.click();
    URL.revokeObjectURL(objectUrl);
  }

  // ฟังก์ชันที่ใช้แปลง Blob URL เป็น Base64
  async convertBlobUrlToBase64(blobUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data);
            return base64data;
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  }

  convertFormatDateToParam(params: any) {
    if (params) {
      let date: any = moment(params, 'DD/MM/YYYY');
      date = moment(date.format('DD/MM/YYYY') + '00:00', 'DD/MM/YYYY HH:mm');
      return date.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    } else {
      return null;
    }
  }

  scrollToTop() {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  getMultiDelBtnLabel(selectedRows: any): string {
    const count = selectedRows.length;
    return `ลบ${count > 0 ? ` (${count})` : ''}`;
  }

  getApplicationIdByApplicationCode(value: any) {
    let userProfile = this.sessionStorageService.getUserProfile();
    let findApplicationCode = userProfile?.mainMenu.find(
      (data: any) => data.applicationCode == value
    );
    return findApplicationCode.applicationId;
  }
}

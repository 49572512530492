import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, retry, switchMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/api/auth.service';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private sessionStorage: SessionStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(2),
      catchError((error: any) => {
        console.error(error);
        if (error?.status === 401) {
          return this.handle401Error(request, next, error);
          // if (error?.error?.status === 106) {
          //   return this.handle401Error(request, next, error);
          // }
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    originalError: any
  ): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        let newHttp: HttpRequest<any>;
        let token = this.sessionStorage.getAccessToken();
        newHttp = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
        return next.handle(newHttp);
      }),
      catchError((error) => {
        if (error?.status === 410) {
          return this.refreshToken(request, next, originalError);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    originalError: any
  ) {
    try {
      let newHttp: HttpRequest<any>;
      let token = this.sessionStorage.getAccessToken();
      newHttp = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
      return next.handle(newHttp);
    } catch (error) {
      return throwError(() => originalError);
    }
  }
}

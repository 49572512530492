import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private keyAES: string = '5C9315F493AC38F1';
  private keyCustomeAES: string = 'F26E167E37A540AE18B57348C7E00EE4';
  private ivCustomeAES: string = 'B3126EF799DE6DA20754BC993ED03805';
  private shift: number = 3;

  constructor() {}

  gererateHash() {
    return CryptoJS.lib.WordArray.random(128 / 8).toString();
  }

  // Caesar cipher
  encipher(text: string, shift = this.shift) {
    var result = '';

    //loop through each caharacter in the text
    for (var i = 0; i < text.length; i++) {
      //get the character code of each letter
      var c = text.charCodeAt(i);

      // handle uppercase letters
      if (c >= 65 && c <= 90) {
        result += String.fromCharCode(((c - 65 + shift) % 26) + 65);

        // handle lowercase letters
      } else if (c >= 97 && c <= 122) {
        result += String.fromCharCode(((c - 97 + shift) % 26) + 97);

        // its not a letter, let it through
      } else {
        result += text.charAt(i);
      }
    }
    return result;
  }

  decipher(text: any, shift = this.shift) {
    var result = '';
    shift = (26 - shift) % 26;
    result = this.encipher(text, shift);
    return result;
  }

  // Hex to Base64
  hexToBase64(str: {
    replace: (
      arg0: RegExp,
      arg1: string
    ) => {
      (): any;
      new (): any;
      replace: {
        (arg0: RegExp, arg1: string): {
          (): any;
          new (): any;
          replace: {
            (arg0: RegExp, arg1: string): {
              (): any;
              new (): any;
              split: { (arg0: string): number[]; new (): any };
            };
            new (): any;
          };
        };
        new (): any;
      };
    };
  }) {
    return btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, '')
          .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
          .replace(/ +$/, '')
          .split(' ')
      )
    );
  }

  // Base64 to Hex
  base64ToHex(str: string) {
    for (
      var i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = [];
      i < bin.length;
      ++i
    ) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = '0' + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join('').toUpperCase();
  }

  encryptAES128(plainText: string) {
    var key = CryptoJS.enc.Utf8.parse(this.keyAES);
    var iv = CryptoJS.enc.Utf8.parse(this.keyAES);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plainText),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  decryptAES128(cipherText: string | CryptoJS.lib.CipherParams) {
    var key = CryptoJS.enc.Utf8.parse(this.keyAES);
    var iv = CryptoJS.enc.Utf8.parse(this.keyAES);
    var decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptCustomAES128(plainText: any | CryptoJS.lib.WordArray) {
    var _plainText = CryptoJS.enc.Utf16LE.parse(plainText);
    var key = CryptoJS.enc.Hex.parse(this.keyCustomeAES);
    var iv = CryptoJS.enc.Hex.parse(this.ivCustomeAES);
    var encrypted = CryptoJS.AES.encrypt(_plainText, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  }

  decryptCustomAES128(cipherText: string | CryptoJS.lib.CipherParams) {
    var key = CryptoJS.enc.Hex.parse(this.keyCustomeAES);
    var iv = CryptoJS.enc.Hex.parse(this.ivCustomeAES);

    var decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf16LE);
  }
}
